.drawerContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  padding: 32px 16px 24px 16px;
  .contentWrapper {
    width: 100%;
    height: 100%;
  }
}

.drawer {
  margin-top: 65px;
  height: calc(100% - 65px) !important;

  box-shadow:
    inset 0 11px 8px -10px #ccc,
    -11px 0px 8px -10px #ccc,
    inset -11px 0px 8px -10px #ccc !important;
}

.drawerRoot {
  top: 65px;
}
