html,
body,
#root {
  width: 100%;
  height: 100%;
}

.table-header {
  background-color: #f7f2fa;
}

.selected-row {
  background-color: #f3edf7;
}
