.logoTitle {
  text-decoration: none;
  color: black;
  &:hover {
    color: var(--mui-palette-primary-main);
  }
}

.navTextLink {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.contentWrapper {
  width: 100%;
  height: calc(100% - 64.5px);
}
