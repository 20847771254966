.wrapper {
  width: 100%;
  min-height: 72px;
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  .valuesWrapper {
    margin-top: 8px;
  }
  .valueWrapper {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #f7f2fa;
    }
  }
}
