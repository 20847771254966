.label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
  text-transform: none;
  color: black;
}

.icon {
  color: black;
}
